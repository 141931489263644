.nav {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.navhead {
  height: 50px;
  margin-left: 30px;
  margin-top: 0px;
  display: block;
}
.nav_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}
.logo {
  height: 50px;
  width: 224px;
  border-radius: 15;
}

.nav_comp {
  margin-left: auto;
  margin-right: 30px;
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.nav_login {
  margin-right: 60px;
  font-size: 20px;
  font-weight: 500;
  background-color: #ef7e3d;
  border-color: #ef7e3d;
  color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  transition: width 0.3s;
  &:hover {
    background-color: #ffffff;
    color: #ef7e3d;
    border-color: #ef7e3d;
    border-width: 2.5px;
  }
}
.container {
  position: relative;
  text-align: center;
  color: white;
  margin-top: 70px;
  display: block;
}
.centered {
  position: absolute;
  top: 60%;
  left: 10%;
  right: 10%;
}

.imgtext {
  white-space: pre-wrap;
  display: block;
  font-size: 35px;
  font-weight: 700;
}

.contact_main {
  display: block;
  width: 100%;
}
.tag {
  background-color: #f3894c;
  height: 60px;
  margin-top: 50px;
  margin-left: -15px;
  width: 23%;
  border-radius: 20px;
}
.tag_text {
  color: #ffffff;
  font-size: 26px;
  font-weight: 500;
  font-family: "poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
}
.text {
  font-size: 25px;
  font-weight: 550;
  font-family: "Ranga";
  font-style: italic;
  padding: 50px;
}
.con_text1 {
  color: #f3894c;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  padding: auto;
  margin-left: 30px;
}
.con_text2 {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
  float: left;
}
.footer {
  background-color: #5f96ca;
  padding: 100px 0px 10px 150px;
  display: block;
}
.footertext {
  color: white;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 20px;
}
